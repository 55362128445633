import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_PROFILE } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import ViewHeader from 'Components/layout/panel/ViewHeader';
import ProfileForm from 'Components/common/ProfileForm';

export default class AdminProfile extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        profile: PropTypes.shape({
            email: PropTypes.string.isRequired,
            name: PropTypes.string,
            surname: PropTypes.string,
            phone: PropTypes.string,
            address: PropTypes.string,
            postalCode: PropTypes.string,
            city: PropTypes.string,
            avatar: PropTypes.string,
        }),
    };
    static defaultProps = {};

    render() {
        const { profile, history, location } = this.props;

        return (
            <StyledComponent
                className="admin-profile-manage"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_PROFILE.path),
                        label: 'Edycja profilu',
                    }]}
                >
                    <ViewHeader
                        title="Edycja profilu"
                    />
                    <ProfileForm 
                        profile={profile} 
                        history={history}
                        location={location}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}